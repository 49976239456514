<template>
  <div>
    <el-button class="saveBtns" @click="reloadsFN">刷新</el-button>
    <div class="itemBox">
      <div class="itemTitle">普通代购服务费</div>
      <div class="tips">代购服务费=固定金额 + (商品金额 x 服务费比例)</div>
      <div class="feeBox">
        <div class="leftTable">固定金额(元):</div>
        <el-input
          class="feeInput"
          type="number"
          v-model="number1"
          placeholder="请输入内容"
        ></el-input>
        <el-button
          class="saveBtns"
          @click="saveBtnsServiceFee(number1, 'amout')"
          >保存修改</el-button
        >
      </div>
      <div class="feeBox">
        <div class="leftTable">服务费比例(%):</div>
        <el-input
          class="feeInput"
          type="number"
          v-model="number2"
          placeholder="请输入内容"
        ></el-input>
        <el-button class="saveBtns" @click="saveBtnsServiceFee(number2, 'rate')"
          >保存修改</el-button
        >
      </div>
    </div>
    <!-- 专家代购服务费 -->
    <div class="itemBox">
      <div class="itemTitle">专家代购服务费</div>
      <div class="tips">专家代购服务费=固定金额 + (商品金额 x 服务费比例)</div>
      <div class="feeBox">
        <div class="leftTable">固定金额(元):</div>
        <el-input
          class="feeInput"
          type="number"
          v-model="number3"
          placeholder="请输入内容"
        ></el-input>
        <el-button
          class="saveBtns"
          @click="saveBtnsServiceFee_zj(number3, 'amout', '固定金额')"
          >保存修改</el-button
        >
      </div>
      <div class="feeBox">
        <div class="leftTable">专家购服务费比例(%):</div>
        <el-input
          class="feeInput"
          type="number"
          v-model="number4"
          placeholder="请输入内容"
        ></el-input>
        <el-button
          class="saveBtns"
          @click="saveBtnsServiceFee_zj(number4, 'rate', '专家购服务费比例')"
          >保存修改</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      number1: 0,
      number2: 0,
      number3: 0,
      number4: 0,
    };
  },
  watch: {},
  created() {
    this.reloadsFN();
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    reloadsFN() {
      this.getrunReport();
      this.getrunReport_ZJ();
    },
    // 保存

    // insert 新增
    // update 修改
    // select 查询

    saveBtnsServiceFee(val, key) {
      // key: amout,rate
      let param = {
        conditionReqs: [
          {
            conditonKey: "val",
            conditonVal: val,
          },
          {
            conditonKey: "type",
            conditonVal: "dg_service",
          },
          {
            conditonKey: "key",
            conditonVal: key,
          },
        ],
        number: "update_dict_by_type_key",
      };

      this.$API.runUpdateSql(param).then((res) => {
        if (res.data.result.successCount == 1) {
          this.$message.success("保存成功");
        }
      });
    },
    saveBtnsServiceFee_zj(val, key, title) {
      // key: amout,rate
      let param = {
        conditionReqs: [
          {
            conditonKey: "val",
            conditonVal: val,
          },
          {
            conditonKey: "type",
            conditonVal: "zj_dg_service",
          },
          {
            conditonKey: "key",
            conditonVal: key,
          },
          {
            conditonKey: "descript",
            conditonVal: title,
          },
        ],
        number: "update_dict_by_type_key",
      };

      this.$API.runUpdateSql(param).then((res) => {
        if (res.data.result.successCount == 1) {
          this.$message.success("保存成功");
        }
      });
    },
    // 获取数据
    getrunReport() {
      let param = {
        conditionReqs: [
          {
            conditonKey: "type",
            conditonVal: "dg_service",
          },
        ],
        number: "select_dict_by_type",
      };
      this.$API.runReport(param).then((res) => {
        if (res.data.result) {
          let results = res.data.result.datas || [];

          results.forEach((element) => {
            if (element.key == "amout") {
              this.number1 = element.val || 0;
            }
            if (element.key == "rate") {
              this.number2 = element.val || 0;
            }
          });
        }
      });
    },
    // 获取数据(专家)
    getrunReport_ZJ() {
      let param = {
        conditionReqs: [
          {
            conditonKey: "type",
            conditonVal: "zj_dg_service",
          },
        ],
        number: "select_dict_by_type",
      };
      this.$API.runReport(param).then((res) => {
        if (res.data.result) {
          let results = res.data.result.datas || [];

          results.forEach((element) => {
            if (element.key == "amout") {
              this.number3 = element.val || 0;
            }
            if (element.key == "rate") {
              this.number4 = element.val || 0;
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped lang='scss'>
$borderColor: #666;
.itemBox {
  border-bottom: 1px dashed $borderColor;
  min-height: 300px;
  margin-top: 20px;

  .itemTitle {
    font-weight: bold;
  }

  .feeBox {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .feeInput {
      margin-left: 10px;
      width: 200px;
    }
  }
}

.leftTable {
  width: 180px;
  text-align: right;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}

.saveBtns {
  margin: 0 10px;
  background-color: #f0a261;
  color: #fff;
}
</style>